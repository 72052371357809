
@import '../../assets/scss/variables';

.attachmentcard-default {
    background-color: $color-grey-3 !important;
    height: 120px !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: unset;
    position: relative !important;
    cursor: pointer;


    .file-remove,
    .file-preview,
    .arrow-prev,
    .arrow-next {
        position: absolute;
        color: #fff !important;
        font-size: 12px;
        background-color: $color-red !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);

        &.file-remove {
            top: 4px;
            right: 4px;
        }
    
        &.file-preview {
            bottom: 4px;
            left: 4px;
        }
    
        &.arrow-prev {
            top: calc(50% - 10px);
            left: -10px;
        }

        &.arrow-next {
            top: calc(50% - 10px);
            right: -10px;
        }
    }
    

    .MuiImageListItem-img {
        height: 100% !important;
    }


    .file-icon {
        font-size: 16px;
        margin-top: 12px;
    }

    .file-name {
        font-size: 12px;
        display: block;
        width: 80%;
        margin: 8px auto 12px auto;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}