
@import '../../assets/scss/variables';

.store-container-background {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    transition: 0.2s;
    opacity: 0;
    z-index: 99;
}

.store-container-background.actived {
    visibility: visible;
    opacity: 1;
}


.store-container {
    width: 320px;
    position: fixed;
    top: 0;
    right: -320px;
    height: 100%;
    background-color: $color-primary !important;
    padding: 20px !important;
    z-index: 999;
    transition: 0.2s;

    &.actived {
        right: 0px;
    }

    .default-avatar {
        background-color: rgba(0, 0, 0, 0.25) !important;
        font-size: 20px !important;
    }

    .store-menu {
        border-radius: $borderradius-default !important;
        background-color: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        display: grid;
        grid-template-columns: calc(320px - 40px - 92px) 92px;
        grid-template-rows: 1fr;
        grid-template-areas: 'data picture';
        transform: scale(0);
        transition: 0.2s;

        &.actived {
            transform: scale(1);
        }

        .store-data {
            grid-area: data;
            padding: 12px 20px;

        }

        .store-picture {
            grid-area: picture;
            padding: 12px;
            background-color: $color-grey-3 !important;
            border-radius: 0px $borderradius-default $borderradius-default 0px !important;
            display: flex;
            flex-direction: column;
            align-items: center;

            .store-avatar {
                width: 52px;
                height: 52px;
                margin-bottom: 8px;
            }
        }
    }

    .container-ranking {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #fff;
        padding: 16px;

        &.first {
            border-radius: $borderradius-default 0px 0px $borderradius-default !important;
            align-items: center;
        }

        &.last {
            border-radius: 0px $borderradius-default $borderradius-default 0px !important;
        }
    }
}


.storelist-menuitem,
.advisorlist-menuitem {
    padding: 8px 12px;
    color: $color-red-2 !important;
    cursor: pointer;

    &:hover {
        background-color: $color-red-2 !important;
        color: #fff !important;
    }
}


.iconbutton-storecontainertrigger {
    &.is-not-store {
        background-color: $color-red !important;
        color: #fff !important;
        font-size: 12px !important;
        padding: 0px 12px!important;
    }

    &.displaying-header {
        display: none;
    }
}



@media screen and (max-width: 822px) {
    .iconbutton-storecontainertrigger {
        &.is-not-store {
            font-size: 8px !important;
        }
    }
}



@media screen and (max-width: 767px) {
    .store-container {
        width: 80%;
        max-width: 320px;
        right: -100%;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
    }


    .iconbutton-storecontainertrigger {
        &.is-not-store {
            padding: 0px!important;
        }

        &.displaying-header {
            display: inline-flex !important;
        }
        
        &:not(.displaying-header) {
            display: none !important;
        }
    }
}