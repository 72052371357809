// Responsive Dimensions
$screen-size-xs: 0px;
$screen-size-sm: 600px;
$screen-size-md: 900px;
$screen-size-lg: 1200px;
$screen-size-xl: 1536px;

// Colors
$content-background-color: #f8f9fe;


$component-default-background-color-red: #e32528;
$component-default-background-color-green: #00dc94;
$component-default-background-color-blue: #0075dc;
$component-default-background-color-cyan: #26e7e7;
$component-default-background-color-grey-1: #f4f5fa;
$component-default-background-color-grey-2: #c3cfda;
$component-default-background-color-grey-3: #e0e0e0;

$component-textfield-default-border-color-actived: #424242;
$component-selectfield-default-border-color-actived: #424242;

$color-primary: #e32528;
$secondary-color: #e32528;
$color-red: #e32528;
$color-red-2: #be2323;
$color-red-3: #ffebee;
$color-yellow: #fab32c;
$color-green: #00dc94;
$color-blue: #0075dc;
$color-orange: #ef6c00;
$color-cyan: #26e7e7;
$color-grey-1: #f4f5fa;
$color-grey-2: #c3cfda;
$color-grey-3: #e0e0e0;
$color-grey-4: #9ba2aa;
$color-purple-1: #070416;
$color-bandag: #104571;

// Radius
$borderradius-default: 20px;
$borderradius-card: 8px;

$component-default-border-radius: 8px;
$component-textfield-default-border-radius: 8px;
$component-selectfield-default-border-radius: 8px;
$component-button-default-border-radius: 32px;

// ExcellenceSubscription
$screen-excellencesubscription-header-height: 180px;
$screen-excellencesubscription-footer-height: 44px;

$screen-excellencesubscription-body-background: #f4f5fa;
$screen-excellencesubscription-header-background: #e32528;
$screen-excellencesubscription-footer-background: #060616;

$screen-excellencesubscription-paper-border-radius: 20px;
$screen-excellencesubscription-stepper-active-color: #00dc94;

$screen-excellencesubscription-cardmanager-border-radius: 8px;

// SacPersonal
$screen-sacpersonal-header-height: 120px;
$screen-sacpersonal-footer-height: 44px;

$screen-sacpersonal-body-background: #f4f5fa;
$screen-sacpersonal-header-background: #e32528;
$screen-sacpersonal-footer-background: #060616;

$screen-sacpersonal-paper-border-radius: 20px;
