@import '../../../assets/scss/variables';

.infotask-tooltip-blank {
    width: 28px;
    height: 28px;
    background: #fff;
}

.infotask-tooltip {
    border-radius: $borderradius-default !important;
    padding: 0px !important;
    width: 320px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3) !important;
    margin-right: 10px;

    .infotask-title {
        text-align: center !important;
        border-top-left-radius: $borderradius-default !important;
        border-top-right-radius: $borderradius-default !important;
    }

    .arrow-prev,
    .arrow-next {
        position: absolute;
        color: #fff !important;
        font-size: 12px;
        background-color: $color-red !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);

        &.file-remove {
            top: 4px;
            right: 4px;
        }
    
        &.file-preview {
            bottom: 4px;
            left: 4px;
        }
    
        &.arrow-prev {
            top: calc(50% - 0px);
            left: 5px;
        }

        &.arrow-next {
            top: calc(50% - 0px);
            right: 5px;
        }
    }
}

@media screen and (max-width: $screen-size-md) {
    .download-button {
        width: 100%;
    }
}
