@import "./variables";

.color-secondary {
  color: $secondary-color !important;
}
.background-secondary {
  background: $secondary-color !important;
}

.header-gradient {
  background: linear-gradient(87deg, #ed1c24 0, #8d0b0f 100%) !important;
  padding-top: 8rem !important;
}

.header-gradient-dash {
  background: linear-gradient(87deg, #ed1c24 0, #8d0b0f 100%) !important;
  padding-top: 28rem !important;
}

.mt--20 {
  margin-top: -31rem !important;
}

.mt--26 {
  margin-top: -31rem !important;
}

.avatar {
  background-color: transparent;
}

.navbar-brand-img {
  max-height: 6.5rem !important;
  margin-bottom: -20px !important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .pdfWrapper iframe {
    height: 400px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .pdfWrapper iframe {
    height: 600px;
  }
}

.navbar-brand-img {
  position: relative;
  width: 140px;
  max-width: unset;
  background-color: #e32528;
  border-radius: 100%;
  padding: 6px 4px;
}

@media screen and (max-width: 770px) {
  .navbar-brand-img {
    position: relative;
    top: -10px;
    background-color: transparent;
    border-radius: unset;
    padding: 0px;
  }
}

.container-fluid.padding-none {
  padding: 0px !important;
}

.dashboard-canvas > canvas {
  margin: 0 auto !important;
}
